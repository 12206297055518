<template>
    <tabla-datos :botones="true" :columnas="columnas" :datos="expedientes" :filtros="filtros"
        :filtrosBuscador="filtrosBuscador" :total="total" @quieroDatos="obtenerExpedientes($event)" >
    </tabla-datos>
    <Dialog modal :header="cabecera" :style="{ width: '50rem' }" :breakpoints="{ '1199px': '75vw', '575px': '90vw' }"
        v-model:visible="visible">
        <p v-html=" mensaje">
        </p>
    </Dialog>
</template>
<script>
import { PwgsApi } from '../../../services/PwgsApi';
import TablaDatos from '../../TablaDatos.vue';
//import { FilterMatchMode } from 'primevue/api';
import Dialog from 'primevue/dialog'; export default {
    components:
    {     
        "tabla-datos": TablaDatos,
        Dialog,
    },
    data() {
        return {
            visible:false,
               total: '',
               expedientes: [],
               parametros: '',
               cabecera:'',
               mensaje:'',
               filtrosBuscador: [],
               filtros: {
                   
               },
               columnas: [
                   {
                       header: 'Tipo',
                       field: 'class',
                   },
                
                   {
                       header: 'Expediente',
                       field: 'codigo_servicio',
                       sortable: true,
                       acciones: [
                           {
                               link: true,
                               tipo: 'default',
                               nombreId: 'id_servicio',
                               // Debe ser arrow function para que this sea este componente
                               accion: (id) => {
                                   console.log('Editar expediente ' + id);
                                   this.$router.push({
                                       name: 'Servicio',
                                       params: {
                                           id: id
                                       }
                                   });
                               }
                           },
                       ]
                   },
                   {
                       header: 'Compañia',
                       field: 'nombre_compania',
                   },
                   {
                       header: 'Fecha/Hora',
                       field: 'fecha_hora',
                       sortable: true,
                   },
                   {
                       header: 'Operario',
                       field: 'nombre_operario',
                   },
                   {
                       header: 'Estado Pendiente',
                       field: 'nombre_especialidad_pendiente',
                   },
                   {
                       header: 'Especialidad',
                       field: 'nombre_especialidad',
                   },
                   {
                       data: null,
                       header: "Notas",
                       acciones: [
                           {
                               nombre: 'Nota',
                               class: 'nota',
                               tipo: 'default',
                               nombreId: 'texto_nota',
                               nombreIddos: 'codigo_servicio',

                               icono: 'far fa-clipboard',
                               // Debe ser arrow function para que this sea este componente
                               accion: (texto_nota,codigo_servicio) => {
                                   this.mensaje = texto_nota;
                                   this.cabecera = codigo_servicio;
                                   this.visible = true;
                                                                  }
                           },
                       ]
                   },
                   {
                       data: null,
                       acciones: [
                           {
                               nombre: 'Editar',
                               class: 'editar',
                               tipo: 'default',
                               nombreId: 'id_servicio',
                               icono: 'far fa-edit',
                               // Debe ser arrow function para que this sea este componente
                               accion: (id) => {
                                   console.log('Editar expediente ' + id);
                                   this.$router.push({
                                       name: 'Servicio',
                                       params: {
                                           id:id
                                       }
                                   });
                               }
                           },
                       ]
                   }
               ]
           }
       },
       methods: {
           //https://sonia.pwgs.app/modulos/pwgsapi/index.php/listados-servicios/activos
           async obtenerExpedientes(event) {
             
               const api = new PwgsApi;
               const aux = await api.post('avisos/trabajos-no-revisados',event);
               this.expedientes = aux.datos;
               this.total = aux.n_total_registros;
               this.$emit('evento_total_trabajos_no_revisados', this.total); //Es llamado por ./Escritorio.vue
           },
           toggle(event) {
               this.$refs.op.toggle(event);
           }
       },
       activated() {
           //this.obtenerExpedientes();
       },
           mounted() {
           //this.obtenerExpedientes();
       }
   }
   </script>
   <style>
   .p-dialog {
       width: 90%;
   }
   .p-datatable-header{
   
       visibility:hidden;
   }
   </style>